<template>
  <div class="col-lg-12">
    <hot-offer-icon class="hot-offer-icon" :color="item.badgeColor" :text="item.badgeText" v-if="isBadge"/>

    <div class="lesiurpackages_hotelsbox domestic_hotelsbox" :class="isBig && 'big'">
      <img :src="imageUrl"
        width=""
        height=""
        alt=""
        v-bind="mainProps"
        onerror="this.src='assets/img/lesiurpackages_hotelsimg6.jpg'"
      />
      <div class="disc" v-if="(isOdysseySite && isMEMSHAKAgent && discountPercent > 0 && !isBadge) || (!isOdysseySite && discountPercent > 0 && !isBadge)">
        <strong>{{discountPercent + memberDiscountPercent}}%</strong><br /> {{ $t("home.discount") }}
      </div>
      <div class="lesiurpackages_hotelstextbox">

        <h3> {{ item.hotelNameTrans[lang] || item.hotelName }}, {{ item.extendedProperties.destination.name}}</h3>
        <p class="item-sub-title">
          <i class="fa fa-calendar-alt"></i> <span dir="ltr">{{ changeDateFormat(item.checkinDate) }} - {{ changeDateFormat(item.checkoutDate) }}</span>
        </p>

        <a v-if="isLanding" :href="productPageLink"><div class="place btn-primary">{{ $t("home.domestic-order-button") }}</div></a>
        <router-link v-else :to="productPageLink"><div class="place btn-primary">{{ $t("home.domestic-order-button") }}</div></router-link>

        <div class="d-flex w-100 d-inline-block">
          <div class="clock">
            <p><i class="fa fa-question-circle"></i> {{$t("home.what-including-in-price")}}</p>
            <p>
              <i class="fa fa-check"></i>
              {{$t("home.total-night")}}: {{ calcNight(item.checkoutDate, item.checkinDate) }}
              {{ (calcNight(item.checkoutDate, item.checkinDate) > 1) ? $t("home.nights") : $t("home.night") }}
           </p>
            <p><i class="fa fa-check"></i>{{ item.priceRemark }}</p>

          </div>
          <div class="align-items-center" :class="(device==='desktop') && isBig ? 'd-inline-flex': 'd-inline-block'" style="width:30%;">
            <p class="price">
              <span class="currency-symbol">$</span><strong>{{discountedPrice}}</strong>
            </p>
            <p style="font-size:0.8rem;"> {{$t("home.total-price-for-the-package")}} </p>
            <p v-if="isOdysseySite && !isMEMSHAKAgent && (!isBig || device==='mobile')" class="mt-1">{{$t("home.this-order-has")}} <span>{{`${commission}%`}}</span></p>
          </div>
          <div class="align-self-md-end" v-if="isOdysseySite  && !isMEMSHAKAgent && (device==='desktop') && isBig">
            <p>{{$t("home.this-order-has")}} <span>{{`${commission}%`}}</span></p>
          </div>
        </div>
      </div>
    </div>

    <countdown class="collapse-button"
      :end-time="item.specialDiscount.endDate" @finish="finishCountdown" v-if="isTimer">
      <template v-slot:process="time">
        <h5>
          <b-badge variant="success" v-if="time.timeObj.d !== '0'">
            {{ $t("home.count-down-day", { day: time.timeObj.d, hour: time.timeObj.h, minute: time.timeObj.m, second: time.timeObj.s}) }}
          </b-badge>
          <b-badge variant="success" v-else-if="time.timeObj.d === '0' && time.timeObj.h !== '00'">
            {{ $t("home.count-down-hour", { hour: time.timeObj.h, minute: time.timeObj.m, second: time.timeObj.s}) }}
          </b-badge>
          <b-badge variant="success" v-else-if="time.timeObj.d === '0' && time.timeObj.h === '00' && time.timeObj.m !== '00'">
            {{ $t("home.count-down-minute", { minute: time.timeObj.m, second: time.timeObj.s}) }}
          </b-badge>
          <b-badge variant="success" v-else>
            {{ $t("home.count-down-second", { second: time.timeObj.s}) }}
          </b-badge>
        </h5>
      </template>
    </countdown>
    <MoreDealCollapse :index="item.id" :moreItems="moreItems" class="collapse-button" v-if="moreItems && moreItems.length > 0"/>
    <!-- <b-badge variant="warning" class="collapse-button" v-if="item.isSalesDeal && isSalesDealExpired">{{$t("home.count-down-expired")}}</b-badge> -->

  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { BBadge } from 'bootstrap-vue';
import dayjs from 'dayjs';
// import dealProductLinkMixin from '@/mixins/dealProductLink';

const customParseFormat = require('dayjs/plugin/customParseFormat');

dayjs.extend(customParseFormat);

export default {
  components: {
    BBadge,
    HotOfferIcon: () => import('@/components/atoms/HotOfferIcon'),
    countdown: () => import('@/components/atoms/CountDown'),
    MoreDealCollapse: () => import('@/components/atoms/MoreDealCollapse'),
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    isBig: {
      type: Boolean,
      default: false,
    },
  },
  // mixins: [dealProductLinkMixin],
  computed: {
    ...mapGetters({
      isLanding: 'GET_IS_LANDING_PAGE',
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
      marketerId: 'GET_MARKETER_ID',
      isMemberLogin: 'IS_AUTHORIZED',
      memberDiscountPercent: 'GET_DISCOUNT_PERCENT',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      isMEMSHAKAgent: 'IS_MEMSHAK_ODYSSEY_AGENT_CODE',
    }),
    item() {
      const valueArray = this.items[1];
      return (valueArray) ? valueArray[0] : null;
    },
    moreItems() {
      const valueArray = this.items[1];
      return (valueArray && valueArray.length > 1) ? valueArray.slice(1) : [];
    },
    isBadge() {
      return this.item?.badgeColor && this.item?.badgeText;
    },
    isTimer() {
      return this.item?.specialDiscount && this.item?.specialDiscount.endDate && new Date(this.item?.specialDiscount.endDate) > new Date();
    },
    discountPercent() {
      return (this.item?.specialDiscount?.discountPercent && new Date(this.item?.specialDiscount?.endDate) > new Date()) ? this.item?.specialDiscount?.discountPercent : this.item?.appliedDiscountPercent;
    },
    imageUrl() {
      const { item } = this;
      let url = '';

      if (!item) return '';
      url = (item.imageUrl !== 'null') ? url = item.imageUrl : null;
      url = url || item?.extendedProperties?.hotelInfo?.mainImageUrls?.[0] || item?.extendedProperties?.hotelConversion?.imageUrl || 'assets/img/lesiurpackages_hotelsimg5.jpg';
      return url;
    },
    productPageLink() {
      const { marketerId, item, imageUrl } = this;
      let url = `${item.productPageUrl}&dealId=${item.id}&dealCategoryId=${item.dealCategoryIds[0]}&mainimg=${imageUrl}`;
      if (marketerId && marketerId !== '') url = `${url}&marketerId=${marketerId}`;
      return url;
    },
    discountedPrice() {
      const { totalAfterDiscount, totalPrice } = this.item;
      if (this.isOdysseySite && !this.isMEMSHAKAgent) return Math.round(totalPrice);
      if (!this.isMemberLogin) return Math.round(totalAfterDiscount);

      const isAtlantis = this.item.atlantisDiscounted;
      const isCreditCardRemarkHotel = this.item.cardRemarkDiscounted;
      const { memberDiscountPercent } = this;
      const price = (isAtlantis || isCreditCardRemarkHotel) ? totalAfterDiscount : totalAfterDiscount * (1 - memberDiscountPercent / 100);
      return Math.round(price);
    },
    commission() {
      const { isMEMSHAKAgent, item } = this;
      const { extendedProperties } = item;
      if (extendedProperties.sourceName === 'ATLANTIS') return 5;
      let { commission } = extendedProperties.hotelConversion;
      if (!isMEMSHAKAgent) {
        commission = Number(commission) - 5;
      }
      return commission;
    },
  },
  watch: {
    lang() {
      // console.log(this.lang);
    },
  },
  data() {
    return {
      mainProps: {
        blankColor: '#bbb',
        class: 'card-img-top img-fluid',
      },
      isSalesDealExpired: false,
    };
  },
  methods: {
    changeDateFormat(str) {
      return str.split('T')[0].split('-').reverse().join('/');
    },
    calcNight(day1, day2) {
      const a = dayjs(this.changeDateFormat(day1), 'DD/MM/YY');
      const b = dayjs(this.changeDateFormat(day2), 'DD/MM/YY');
      return a.diff(b, 'day');
    },
    finishCountdown() {
      this.isSalesDealExpired = true;
    },
  },
};
</script>

<style scoped>
.collapse-button {
  position: absolute;
  z-index: 1;
  left: 30px;
  top: 10px;
}
.lesiurpackages_hotelsbox .lesiurpackages_hotelstextbox div.clock p:first-child{
  margin-top: revert;
}
.lesiurpackages_hotelsbox:not(.big) .lesiurpackages_hotelstextbox div.clock{
  width:80%;
}
.lesiurpackages_hotelsbox .lesiurpackages_hotelstextbox .item-sub-title{
  text-align:center;
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: 600;
}
.price{
  padding-top: 20px;
}
.place {
  padding: 8px 10px;
  content: "";
  background: #005ca3;
  color: #fff;
  text-align: center;
  text-transform: capitalize;
  border-radius: 10px;
  z-index: 91;
  width: 120px;
  margin: 10px auto;
  border: 1px solid rgb(255 255 255 / 0.5);
  font-weight: bold;
}
.lesiurpackages_hotelstextbox a:hover {
  text-decoration: none;
}
.lesiurpackages_box.Oregenizedbox .lesiurpackages_hotelstextbox p {
  margin-bottom: 20px;
}

/* .domestic_hotelsbox{
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 30px;
  position: relative;
  background: whitesmoke;
  padding: 10px;
  box-shadow: #e0dede 0.5px 0.5px 5px;
}
.domestic_hotelsbox img{
  width: 100%;
  transition: 0.5s;
  object-fit: cover;
  height: 250px;
} */
.domestic-hotel-name{
  font-weight: bold;
  font-size: 1.3em;
}
.domestic_hotelstextbox{
  background: white;
  padding-bottom: 20px;
}
.last-paragraph{
  border-bottom: 1px solid;
}
.what-description{
  font-weight: bold;
}
.text-color-blue{
  color:#1425f5;
}
/* .domestic_hotelsbox .price strong {
    font-size: 4em;
}
.lesiurpackages_hotelsbox.big .currency-symbol, .domestic_hotelsbox .currency-symbol{
  font-size: 2em;
}
.domestic_hotelsbox .place{
  width: fit-content;
}
.domestic_hotelsbox .disc {
  position: absolute;
  top: 40px;
  left: calc(50px - 50%);
  font-size: 1.2em;
  line-height: 12px;
  box-sizing: border-box;
  padding: 8px 4px;
  color: #fff;
  z-index: 1;
  text-align: center;
  width: 100%;
  height: 25px;
  background: #f00;
  transform: rotate(-45deg);
  box-shadow: black 1px 1px 5px;
} */

.lesiurpackages_hotelsbox.domestic_hotelsbox .place {
  width: fit-content;
}
.domestic_hotelsbox.lesiurpackages_hotelsbox:not(.big) .lesiurpackages_hotelstextbox div.clock{
  width: auto;
}
.domestic_hotelsbox.lesiurpackages_hotelsbox .lesiurpackages_hotelstextbox div.clock p{
  white-space: break-spaces;
  display: flex;
  margin-bottom: 3px;
}
.domestic_hotelsbox.lesiurpackages_hotelsbox img {
  filter: brightness(0.8);
}
.domestic_hotelsbox.lesiurpackages_hotelsbox:hover img {
  filter: brightness(1);
}
.domestic_hotelsbox.lesiurpackages_hotelsbox .lesiurpackages_hotelstextbox .d-inline-block p {
  font-size: 0.9rem;
}
.domestic_hotelsbox.lesiurpackages_hotelsbox .lesiurpackages_hotelstextbox div.clock i {
  margin-left: 5px;
}
.img-content{
  overflow: hidden;
  position: relative;
}
@media (max-width: 991px) {
  .lesiurpackages_hotelsbox .lesiurpackages_hotelstextbox {
    text-align: center;
  }
}
@media (min-width: 480px) {
  .lesiurpackages_hotelsbox.big .lesiurpackages_hotelstextbox .item-sub-title{
    margin-bottom: 130px;
  }
  .lesiurpackages_hotelsbox.big h3{
    font-size: 3em;
  }
  .lesiurpackages_hotelsbox.big .lesiurpackages_hotelstextbox .item-sub-title{
    font-size: 2em;
  }
  .lesiurpackages_hotelsbox.big .place{
    padding: 10px 10px;
    font-size: 1.2rem;
  }
  /* .lesiurpackages_hotelsbox:not(.big) .price, .domestic_hotelsbox .price{
    text-align: center;
  } */
  .lesiurpackages_hotelsbox:not(.big) .lesiurpackages_hotelstextbox .d-inline-block p{
    line-height: 1em;
  }
}

  .lesiurpackages_hotelsbox .lesiurpackages_hotelstextbox div.clock p{
    white-space: nowrap;
  }
@media (max-width: 480px) {
  .lesiurpackages_hotelsbox .lesiurpackages_hotelstextbox .d-inline-block p {
    font-size: 0.9em;
  }
  .lesiurpackages_hotelsbox {
    height: 350px;
  }
  /* .domestic_hotelsbox .domestic_hotelstextbox h4{
    font-size: 1.3rem;
  } */
  .lesiurpackages_hotelsbox.big .currency-symbol{
    font-size: initial;
  }
  .lesiurpackages_hotelsbox .lesiurpackages_hotelstextbox{
    padding: 4px
  }
}
.hot-offer-icon {
  width: 100px;
  height: 100px;
  top: -6px;
  right: 9px;
  position: absolute;
  /* transform: rotate(90deg); */
  z-index: 1;
}
</style>

<style>
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .domestic_hotelsbox.lesiurpackages_hotelsbox:not(.big) .lesiurpackages_hotelstextbox div.clock{
    width: 80% !important;
  }
  .domestic_hotelsbox.lesiurpackages_hotelsbox .lesiurpackages_hotelstextbox div.clock p{
    white-space: normal !important;
  }
  .lesiurpackages_hotelsbox.domestic_hotelsbox .place {
    width: 80% !important;
  }
}
</style>
